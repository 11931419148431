import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const CotPage = () => {

  return (
    <Layout>
      <Seo
        title="Ms Fisher and the Crypt of Tears"
        description="Our contribution to the Australian silver screen"
      />

      <div className="mx-4 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="mb-4 text-5xl font-bold text-center text-anomaly-red">Crypt of Tears</h1>
        <p className="my-10 text-2xl font-medium text-center text-gray-500">Phryne Fisher walks past our mark on her way to solving crime.</p>
      </div>

      <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
        <div className="flex flex-col justify-between mt-10 lg:flex-row">
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Kickstarter</h3>
            <p className="text-gray-500">
              Anomaly is proud to have been one of 7,763 backers
              who <a href="https://www.kickstarter.com/projects/468758721/miss-fisher-the-movie">pledged</a>
              AU$ 733,210 to helped bring Miss Fisher to the silver screen.
            </p>
            <p className="text-gray-500">
              Our reward ended up as rather special with Anomaly’s logo mark featured in the movie as a relevant business for the time.
            </p>
          </section>
          <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Miss Fisher</h3>
            <p className="text-gray-500">
              <a href="https://www.abc.net.au/tv/phrynefisher/">Miss Fisher</a> and the Crypt of Tears is a standalone
              feature film that was budgeted at $8 million and directed by Tony Tilse. It premiered in January 2020.
            </p>
          </section>
          <section className="mb-8 lb:pb-0 lg:w-1/3">
            <h3 className="mb-4 text-2xl font-semibold text-anomaly-red">Supporting the arts</h3>
            <p className="text-gray-500">
              Anomaly boasts about being an all Australian company and we extend this sentiment of pride to
              supporting what is all Australian. We were glad to have the opportunity to help fund the movie.
            </p>
          </section>
        </div>
      </div>

      <StaticImage
        src="../images/everycloud-msfisher-anomaly.jpg"
        alt="Anomaly Hero"
        placeholder="dominantColor"
      />

    </Layout>
  );
};
export default CotPage
